import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Image,
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
// import { useSelector } from 'react-redux';
import { CONSENT_DETAILS } from '../../../constants';
import ConsentForm from '../../business/Dashboard/MainContent/DashboardAccountAccordion/ConsentForm';
import './consentFormPopup.scss';
import Modal from '../Modal';
import { dataFetcher } from '../../../dataFetcher';

/**
 * @description - Get Setting for React-slick carousel.
 * @param {Function} setNextIndex - Set next index state.
 * @param {Function} setCurrentIndex - Set current index state.
 * @returns {object} - Settings object.
 */

/**
 * @description - Get Titles to display
 * @param {object} item - Carousel item.
 * @param {string} classVal - class name.
 * @returns {Node} - Titles texts
 */

/**
 * @description - Carousel Block component using slick.
 * @param {object} props - Input props;
 * @returns {Node} - HTML carousel nodes.
 */
const ConsentFormPopUp = ({ ConsentFormData, fields, sitecoreContext }) => {
  const [modalVisible, setModalVisible] = useState(true);
  const [consent, setConsent] = useState(false);
  // const loginReducer = useSelector((state) => state.loginReducer);
  const isMultiCountryAllowed =
    sitecoreContext?.ADB2C?.isMultiCountryAllowed || false;
  const delaySeconds = fields?.popupDelayTimeSec?.value || 0;

  const closeModal = () => {
    setModalVisible(false);
    setConsent(false);
    localStorage.setItem('isRedirectFromAdb2c', false);
  };

  // const signup_check =
  //   loginReducer.idToken?.idTokenClaims?.email &&
  //   loginReducer.idToken?.idTokenClaims?.hasOwnProperty(
  //     'extension_TermsOfUseConsented'
  //   );
  const showConsentPopUp = sitecoreContext?.Country?.requireConsentPopUp;

  useEffect(() => {
    const isRedirectFromAdb2c = localStorage.getItem('isRedirectFromAdb2c');
    if (
      (isMultiCountryAllowed || showConsentPopUp) &&
      isRedirectFromAdb2c === 'true'
    ) {
      setTimeout(() => {
        dataFetcher(`${CONSENT_DETAILS.IS_POPUP_REQUIRED}`).then(
          (response) => {
            if (response?.data && response?.data === true) {
              setConsent(true);
            }
          },
          () => {}
        );
      }, delaySeconds);
    }
  }, []);
  return (
    <>
      {consent && (
        <Modal
          className="consent_popup_modal"
          backdrop={`static`}
          backdropClassName="consent_popup"
          showModal={modalVisible}
          handleModal={closeModal}
          size="lg"
        >
          <div className="inner-modal-container" key="body">
            <div className="logo-image">
              <Image field={fields.image} />
            </div>
            <h2 className="title">
              <Text field={fields.title} />
            </h2>
            {fields.subTitle && (
              <div className="light heading heading-6">
                <Text field={fields.subTitle} />
              </div>
            )}
            <div className="confirm-button-container">
              <ConsentForm
                fields={ConsentFormData[0]?.props?.fields}
                closeConsentPopup={closeModal}
                isConsentPopUp={true}
                ctaText={fields.ctaText}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

ConsentFormPopUp.defaultProps = {
  fields: {},
};

ConsentFormPopUp.propTypes = {
  fields: PropTypes.shape({}),
};

export default withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-consent', prop: 'ConsentFormData' }])(
    ConsentFormPopUp
  )
);

/*eslint-disable*/
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import DropboxChooser from 'react-dropbox-chooser';
import { getFileFromUrl } from '../../../../../../utils/helperUtils';
import { connect } from 'react-redux';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const Dropbox = ({ inputRef, ...props }) => {
  const { onSuccess, config, setLoader } = props;
  const fileUpload = useRef();

  /**
   * @description To handle upload block select
   */

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      fileUpload.current.click();
    },
  }));

  /**
   * @description To handle file from Dropbox
   * @param {*} file file chosen
   * @returns {undefined} nothing
   */
  const handleSuccess = (file) => {
    setLoader();
    const url = file?.link;
    const baseUrl = 'www.dropbox.com';
    const newUrl = 'dl.dropboxusercontent.com';
    const tempUrl = url.replace(baseUrl, newUrl);
    const fileUrl = tempUrl.trim('?dl=0');
    getFileFromUrl(fileUrl, file?.name, onSuccess);
  };

  return config && config?.DROPBOX && config?.DROPBOX?.apiKey ? (
    <DropboxChooser
      appKey={config?.DROPBOX?.apiKey}
      success={(files) => handleSuccess(files[0])}
    >
      <div ref={fileUpload}></div>
    </DropboxChooser>
  ) : (
    <div />
  );
};
const mapStateToProps = (state) => {
  return {
    config: state.uploadConfigReducer.data,
  };
};

Dropbox.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
};

Dropbox.defaultProps = {
  onSuccess: () => {},
  img: '',
};

const ConnectedComponent = connect(mapStateToProps)(Dropbox);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});

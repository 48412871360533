/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../core/globals/buttons/Button';
import { Components } from '../Utils/helper';
import { isObjNotEmpty } from '../../../utils/helperUtils';
import Error from '../../core/ErrorBoundary/Error';
import PrivateRoute from '../../core/PrivateRoute';
import Loader from '../../core/ErrorBoundary/Loader';
import RouteLeavingGuard from './helper/RouteLeavingGuard';
import CustomOverlayLoader from '../../core/ErrorBoundary/CustomOverlayLoader';
import {
  clearBuildProfileForm,
  clearProfileInfo,
  clearProfileInfoForm,
  clearProfileInfoFormCount,
  clearFormData,
} from '../../../redux/actions/actions';
import {
  getCandidateDetailsApi,
  saveCandidateProfile,
} from '../../../services/apiServices/candidateService';
import { uploadPluginConfigApi } from '../../../services/apiServices/commonService';
import './build_profile.scss';

/**
 * @description - Build Profile component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const BuildProfile = ({ rendering, t, dispatch, ...props }) => {
  //rendering = mockData;
  const { fields, placeholders = [] } = rendering;
  const {
    profileInfo,
    profileInfoFormData,
    buildProfileFormState,
    uploadConfig,
  } = props;
  const { title, saveLink, mandatoryFields, saveEndPoint } = fields;

  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);

  const [buttonClickStatus, setButtonClickStatus] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [triggerArray, setTriggerArray] = useState([]);
  const [invalidArray, setInvalidArray] = useState([]);
  const [isDataFilled, setDataFilled] = useState(false);
  const [noChangesToSave, setNoChanges] = useState(false);
  const mandatorySection = mandatoryFields?.value
    ? mandatoryFields?.value.split(',')
    : [];
  const history = useHistory();

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  useEffect(() => {
    //File Upload Configuration
    if (!isObjNotEmpty(uploadConfig)) uploadPluginConfigApi(dispatch);
    return () => {
      dispatch(clearProfileInfo());
      dispatch(clearFormData());
      dispatch(clearBuildProfileForm());
      dispatch(clearProfileInfoForm());
      getCandidateDetailsApi({}, dispatch);
    };
  }, []);

  const handleApiRefresh = () => {
    setprofileDataLoading(true);
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };

  const isFormDirty = () => {
    let result = false;
    if (Object.keys(buildProfileFormState).length > 0) {
      Object.keys(buildProfileFormState).forEach((k) => {
        if (Object.keys(buildProfileFormState[k]).length > 0) {
          if (Object.keys(buildProfileFormState[k]?.touched).length > 0) {
            result = true;
          }
        }
      });
    }
    return result;
  };

  useEffect(() => {
    const handler = (e) => {
      if (isDataFilled) {
        e.preventDefault();
        const message = t('you-will-loose-do-you-wish-to-continue');
        e.returnValue = message;
        return message;
      }
      return null;
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [isDataFilled]);

  useEffect(() => {
    if (buildProfileFormState) {
      setDataFilled(isFormDirty());
    }
  }, [buildProfileFormState]);

  const saveFn = () => {
    setDataFilled(false);
    setSaveLoading(false);
    toast.success(t('details-saved-successfully'), {
      position: 'top-center',
    });
    history.push(saveLink?.value?.href || '/');
  };

  const noChanges = () => {
    setButtonClickStatus(false);
    setNoChanges(true);
    setTimeout(() => {
      dispatch(clearProfileInfoFormCount([]));
      history.push(saveLink?.value?.href);
    }, 3000);
  };

  const showError = (msg) => {
    document.getElementById('profile-form').scrollIntoView();
    toast.error(t(msg), {
      position: 'top-center',
    });
    dispatch(clearProfileInfoFormCount([]));
    setSaveLoading(false);
    setButtonClickStatus(false);
    setTriggerArray([]);
  };

  const checkValid = () => {
    const validationArray = [];
    Object.keys(buildProfileFormState).forEach((k, i) => {
      if (triggerArray.includes(i)) {
        validationArray.push(buildProfileFormState[k].isValid);
      }
    });
    return !validationArray.includes(false);
  };

  useEffect(() => {
    if (
      buttonClickStatus &&
      profileInfoFormData?.triggeredComponents?.length !== 0 &&
      profileInfoFormData?.triggeredComponents?.length === triggerArray.length
    ) {
      if (
        profileInfoFormData?.validComponents?.length === triggerArray.length &&
        checkValid()
      ) {
        setSaveLoading(true);
        saveCandidateProfile(
          saveEndPoint?.value,
          profileInfoFormData?.data,
          profileInfoFormData?.data?.files,
          saveFn,
          showError,
          false
        );
        dispatch(clearProfileInfoFormCount([]));
      } else {
        if (profileInfoFormData.triggeredComponents.length === triggerArray.length) {
          showError('missing-information-text');
        }
      }
    }
  }, [profileInfoFormData]);

  const formSave = () => {
    setButtonClickStatus(true);
    if (profileInfoFormData?.isFormSubmitting) {
      showError('ongoing-section-api-saving');
      return true;
    }
    let form = {
      triggerList: [],
      invalidList: [],
    };
    Object.keys(buildProfileFormState).forEach((item, i) => {
      if (isObjNotEmpty(buildProfileFormState[item])) {
        if (
          isObjNotEmpty(buildProfileFormState[item]?.touched) ||
          profileInfoFormData?.parse ||
          buildProfileFormState[item]?.isDelete ||
          buildProfileFormState[item]?.isClear ||
          buildProfileFormState[item]?.isUpload
        ) {
          if (
            buildProfileFormState[item].isValid ||
            buildProfileFormState[item]?.isClear
          ) {
            form.triggerList.push(item);
          } else {
            form.invalidList.push(item);
          }
        }
      }
    });
    const mandatorySectionList = [
      ...new Set([...mandatorySection, ...form.triggerList]),
    ];
    for (let item in mandatorySectionList) {
      if (
        !buildProfileFormState?.[mandatorySectionList[item]]?.isValid &&
        !form.invalidList.includes(mandatorySectionList[item])
      ) {
        form.invalidList.push(mandatorySectionList[item]);
      }
    }
    if (form.invalidList.length > 0) {
      setInvalidArray(form.invalidList);
      showError('missing-information-text');
    } else if (form.triggerList.length > 0 && form.invalidList.length === 0) {
      setTriggerArray(form.triggerList);
    } else {
      noChanges();
    }
  };

  return (
    <PrivateRoute>
      <section className="candidate-flow">
        <h2 className="title">{title?.value}</h2>
        {profileDataLoading ? (
          <Loader
            loaderMsg={t('fetching-your-data')}
            loaderWithMsg
            customLoaderStyle={{
              width: '4rem',
              height: '4rem',
              color: '#C25700',
            }}
            customTextStyle={{ color: '#C25700' }}
          />
        ) : error ? (
          <Error
            errorMsg={t('error-message')}
            t={t}
            refreshApi={true}
            handleApiRefresh={handleApiRefresh}
          />
        ) : (
          <BuildProfileContext.Provider value={{ mandatorySection }}>
            {placeholders &&
              placeholders['jss-content'].map((block, index) => {
                const Component = block.componentName
                  ? Components[block.componentName]
                  : '';
                if (!Component) return false;
                return (
                  <Component
                    t={t}
                    key={block?.uid}
                    fields={block?.fields}
                    componentName={block.componentName}
                    data={
                      block?.placeholders && block?.placeholders['jss-content']
                        ? block?.placeholders['jss-content']
                        : []
                    }
                    triggerArray={triggerArray}
                    invalidArray={invalidArray}
                  />
                );
              })}
            <div className="container text-center">
              <div className="submitBtns">
                <div className="col-lg-10 offset-lg-1">
                  <div className="submit-btn saveAndReview">
                    <Button
                      text={t('save-and-review')}
                      handleButtonClick={formSave}
                      isLoading={buttonClickStatus && !saveLoading}
                      isDisabled={buttonClickStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
          </BuildProfileContext.Provider>
        )}
      </section>
      {saveLoading || noChangesToSave ? (
        <CustomOverlayLoader
          showSpinner
          customLoaderMsg={
            noChangesToSave ? t('no-changes-to-save') : t('submitting-details')
          }
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#fff',
          }}
          customTextStyle={{ color: '#fff' }}
          customStyle={{
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
            opacity: '1',
          }}
        />
      ) : (
        ''
      )}
      <RouteLeavingGuard
        when={isDataFilled}
        t={t}
        handleChange={(val) => setDataFilled(val)}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => {
          if (true) {
            if (location.pathname) {
              return true;
            }
          }
          return false;
        }}
      />
    </PrivateRoute>
  );
};

BuildProfile.defaultProps = {
  ProfileContent: [],
  t: (val) => val,
  sitecoreContext: {},
  renderContent: [],
};

BuildProfile.propTypes = {
  ProfileContent: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
  renderContent: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
    buildProfileFormState: state.buildProfileFormReducer,
    uploadConfig: state.uploadConfigReducer.data,
  };
};

export default connect(mapStateToProps)(
  withTranslation()(withPlaceholder('jss-content')(BuildProfile))
);

export const BuildProfileContext = React.createContext();

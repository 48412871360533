import { UPLOAD_CONFIG } from '../actions/actionTypes';
const initialState = {
  data: {},
};
/**
 * @description - Upload the config reducer
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const uploadConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_CONFIG.GET:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default uploadConfigReducer;

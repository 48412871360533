/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Text,
  Placeholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import SocialMediaSignIn from '../SocialMediaSignIn';
import Button from '../../core/globals/buttons/Button';
import TextButton from '../../core/globals/buttons/TextButton';
import DynamicForm from '../../core/Forms/DynamicForm';
import { ROMA_INTEGRATION, API_STATUS_CODES } from '../../../constants';
import { usePostData } from '../../../hooks/http-client';
import SnackBar from '../../core/ErrorBoundary/SnackBar';
import { useSnackbar } from '../../../hooks/useSnackbar';

import '../RomaSignUp/createAccSweden.scss';

/**
 * @description - ROMA SignIn component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const ROMASignIn = (props) => {
  const { fields, t, sitecoreContext } = props;
  const { items = [], Title } = fields;
  const { isActive, message, openSnackBar } = useSnackbar();
  const [param, setParam] = useState(new URLSearchParams());
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    clearErrors,
    control,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      emailAddress: '',
      password: '',
    },
  });
  const history = useHistory();
  const location = useLocation();
  const { write, error, loading } = usePostData();
  const ifItemExists = items?.length > 0;

  useEffect(() => {
    if (error) {
      openSnackBar(t('http-error'));
    }
  }, [error]);

  useEffect(() => {
    setParam(new URLSearchParams(location.search));
  }, [location]);

  /**
   * @description - Handle signin success/failure response.
   * @param {object} response - Response object.
   * @param {object} params -param
   * @returns {undefined}
   */
  const handleSignInResponse = (response, params) => {
    if (response.data.Status === API_STATUS_CODES.SUCCESS) {
      if (!response.data.Data) {
        openSnackBar(t('roma-already-applied'));
      } else {
        if (window?.dataLayer) {
          window.dataLayer.push({
            event: 'logIn',
            type: 'OldAccount',
            eventCategory: 'Sign in to an account',
            eventAction: `Sign in with ${params?.method}`,
            eventLabel: params?.PositionID ? 'Apply > Sign in' : 'Sign in',
          });
        }
        window.location.assign(response.data.Data);
      }
    } else {
      setParamOnError(response.data.Message);
      if (response.data.Status === API_STATUS_CODES.NOT_FOUND) {
        openSnackBar(t('roma-wrong-username-or-password'));
      } else if (response.data.Status === API_STATUS_CODES.DATA_EXIST) {
        openSnackBar(t('roma-username-already-exists'));
      } else if (response.data.Status === API_STATUS_CODES.LIMIT_EXCEEDS) {
        openSnackBar(t('roma-position-deleted'));
      } else {
        openSnackBar(t('roma-error'));
      }
    }
  };

  /**
   * @description - On submit handler.
   * @param {object} values - Form values.
   * @returns {undefined}
   */
  const onSubmit = (values = {}) => {
    write(
      `${ROMA_INTEGRATION.ROMA_AUTHENTICATE}`,
      {
        Request: {
          PositionID: param.get('positionID'),
          Source: param.get('source') || null,
        },
        Application: {
          UserName: values.emailAddress,
          Password: btoa(values.password),
          email: values.emailAddress,
          LegalNoticeSignedDate: '',
          MarketingConsentSignedDate: '',
          Action: 'signin',
          SSOID: '',
        },
      },
      handleSignInResponse,
      {
        PositionID: param.get('positionID'),
        method: 'email',
      }
    );
  };

  /**
   * @description - Social sign In handler.
   * @param {object} values - User values.
   * @returns {undefined}
   */
  const onSocialSignIn = (values = {}) => {
    const tokenParams = {};
    let PositionID = param.get('positionID') || null;
    let Source = param.get('source') || null;
    if (values.SSOID === 'LinkedIn') {
      const { code, state, RedirectUri } = values;
      tokenParams.code = code;
      tokenParams.state = state;
      tokenParams.RedirectUri = RedirectUri;
      PositionID = values.PositionID || null;
      Source = values.Source || null;
    }
    write(
      `${ROMA_INTEGRATION.ROMA_AUTHENTICATE}`,
      {
        Request: {
          PositionID: PositionID,
          Source: Source,
        },
        Application: {
          UserName: values.email,
          Password: values.accessToken,
          email: values.email,
          LegalNoticeSignedDate: '',
          MarketingConsentSignedDate: '',
          Action: 'signin',
          SSOID: values.SSOID,
        },
        ...tokenParams,
      },
      handleSignInResponse,
      {
        PositionID: PositionID,
        method: values.SSOID,
      }
    );
  };

  /**
   * @description - Social sign In error handler.
   * @param {object} error -error
   * @returns {undefined}
   */
  const onSocialSignInError = (error) => {
    if (error.toString().indexOf('Facebook is not initialized.') > -1) {
      openSnackBar(t('onetrust-error'));
    } else {
      openSnackBar(t('facebook-sign-in-error'));
    }
  };

  /**
   *
   * @param {*} paramStr -param string
   * @return {undefined}
   */
  const setParamOnError = (paramStr) => {
    const paramObj = paramStr ? JSON.parse(paramStr) : {};
    const paramVal = new URLSearchParams(location.search);
    paramVal.set('positionID', paramObj.PositionId || '');
    paramVal.set('source', paramObj?.Source || '');
    setParam(paramVal);
  };

  return (
    <section className="create-acc-sw container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h2>
          <Text field={Title} />
        </h2>
        <div className="form-section row  justify-content-md-center">
          <div className="social-media-signin col-lg-5 col-xl-4">
            <SocialMediaSignIn
              onError={onSocialSignInError}
              onSuccess={onSocialSignIn}
              redirectTo={location.pathname?.replace(
                `/${sitecoreContext?.language}/`,
                '/'
              )}
              facebookText={t('roma-sign-in-with-facebook')}
              linkedInText={t('roma-sign-in-with-linkedin')}
              param={param}
              clearErrors={clearErrors}
            />
          </div>
          <div className="wrapper col-lg-1">
            <div className="line"></div>
            <div className="wordwrapper">
              <div className="word">{t('roma-or')}</div>
            </div>
          </div>

          <div className="manual-signin col-lg-5 col-xl-4">
            <DynamicForm
              formData={ifItemExists ? items[0]?.fields?.items : []}
              register={register}
              errors={errors}
              getValues={getValues}
              control={control}
              setValue={setValue}
            />
            <Placeholder name="jss-left" {...props} />
            {/* <ForgetPassword /> */}
          </div>
        </div>
        <div className="footer-section">
          <Button
            text={t('roma-sign-in')}
            handleButtonClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={loading}
          />
          <div className="sign-in">
            <span>{t('roma-already-dont-have-account')}</span> &nbsp;
            <TextButton
              text={
                ifItemExists
                  ? items[1]?.fields?.ctaText?.value
                  : t('roma-create-account')
              }
              handleTextClick={() => {
                if (ifItemExists) {
                  history.push({
                    pathname: items[1]?.fields?.ctaUrl?.value?.href,
                    search: String(param),
                  });
                }
              }}
            />
          </div>
        </div>
        <SnackBar isActive={isActive} message={message} />
      </form>
    </section>
  );
};

ROMASignIn.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    Title: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

ROMASignIn.defaultProps = {
  fields: {
    Title: '',
  },
  sitecoreContext: {},
};

export default withSitecoreContext()(withTranslation()(ROMASignIn));

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../utils/enums';
import { withTranslation } from 'react-i18next';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isNullorEmpty } from '../../../../utils/helperUtils';

const JobDetailsList = ({ ...props }) => {
  const {
    t,
    isApplyASAP,
    datePosted,
    applyBy,
    formOfEmployment,
    jobType,
    remoteJobs,
    domain,
    icons,
    experience,
    salaryRate,
    sitecoreContext,
    noOfPositions,
    hourPerWeek,
    referenceNumber,
    specialisation,
    education,
    applicationType,
    scheduleType,
  } = props;
  let {
    iconHours,
    iconIndustry,
    iconJobTypeDetails,
    iconRemoteJobs,
    iconPublishfromDate,
    iconReferenceNumber,
  } = {};
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  /**
   * @description validate apply by date
   * @param {string} applyBy - apply by date
   * @returns {boolean} - boolean
   */
  const validateApplyDate = (applyBy) => {
    return applyBy && applyBy !== '0001-01-01T00:00:00' ? true : false;
  };
  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'IndustryDetail':
            iconIndustry = iconItem.icon;
            break;
          case 'Hours':
            iconHours = iconItem.icon;
            break;
          case 'RemoteJobDetail':
            iconRemoteJobs = iconItem.icon;
            break;
          case 'ReferenceNumber':
            iconReferenceNumber = iconItem.icon;
            break;
          case 'PublishfromDate':
            iconPublishfromDate = iconItem.icon;
            break;
          case 'JobTypeDetails':
            iconJobTypeDetails = iconItem.icon;
          default:
            break;
        }
      });
    }
  };
  setIcons();
  return (
    <ul className="job-details-list">
      {referenceNumber && countryName !== 'manpower-germany' && (
        <li>
          <div className="job-details-text">
            {countryName === 'us' && (
              <img
                src={mediaApi.updateImageUrl(iconReferenceNumber)}
                alt="Reference icon"
              />
            )}{' '}
            {`${t('reference-number')}: `} {referenceNumber}
          </div>
        </li>
      )}
      {datePosted && validateApplyDate(datePosted) && (
        <li>
          <div className="job-details-text">
            {countryName === 'us' && (
              <img
                src={mediaApi.updateImageUrl(iconPublishfromDate)}
                alt="PublishDate icon"
              />
            )}
            {`${t('posted')}:`} {`${moment(datePosted).format(dateFormat)}`}
          </div>
        </li>
      )}
      {formOfEmployment && (
        <li>
          <div className="job-details-text">
            {countryName === 'us' && (
              <img
                src={mediaApi.updateImageUrl(iconJobTypeDetails)}
                alt="JobType icon"
              />
            )}
            {`${t('form-of-employment')}: `} {formOfEmployment}
          </div>
        </li>
      )}
      {salaryRate && (
        <li>
          <div className="job-details-text">
            {`${t('rate')}: `} {salaryRate}
          </div>
        </li>
      )}
      {Array.isArray(education) && education?.length > 0 && (
        <li>
          <div className="job-details-text">
            {`${t('education')} :`} {education.join(', ')}
          </div>
        </li>
      )}
      {domain && (
        <li>
          <div className="job-details-text">
            {countryName === 'us' && (
              <img src={mediaApi.updateImageUrl(iconIndustry)} alt="domain icon" />
            )}
            {`${t('industry')}: `} {domain}
          </div>
        </li>
      )}
      {hourPerWeek && (
        <li>
          <div className="job-details-text">
            {`${t('hour-per-week ')}: `} {hourPerWeek}
          </div>
        </li>
      )}
      {Array.isArray(specialisation) && specialisation?.length > 0 && (
        <li>
          <div className="job-details-text">
            {`${t('Specialisatie')}: `} {specialisation.toString()}
          </div>
        </li>
      )}
      {validateApplyDate(applyBy) && (
        <li>
          <div className="job-details-text">
            {`${
              isApplyASAP
                ? `${t('apply-by')} : ${t('roma-to-be-applied')}`
                : `${t('apply-by')} : ${moment(applyBy).format(dateFormat)}`
            }`}
          </div>
        </li>
      )}
      {experience && (
        <li>
          <div className="job-details-text">
            {`${t('experience-level')}: `} {experience}
          </div>
        </li>
      )}
      {Array.isArray(scheduleType) && scheduleType?.length > 0 && (
        <li>
          <div className="job-details-text">
            {`${t('jobs-schedule-type')}: `} {scheduleType.join(', ')}
          </div>
        </li>
      )}
      {applicationType && countryName !== 'manpower-germany' && (
        <li>
          <div className="job-details-text">
            {`${t('application-type')}: `} {applicationType}
          </div>
        </li>
      )}
      {noOfPositions && (
        <li>
          <div className="job-details-text">
            {t('roma-no-of-positions')}: {noOfPositions}
          </div>
        </li>
      )}
      {!isNullorEmpty(remoteJobs) && (
        <li>
          <div className="job-details-text">
            {countryName === 'us' && (
              <img src={mediaApi.updateImageUrl(iconRemoteJobs)} alt="remote icon" />
            )}
            {`${t('remote-detail-filter')}: `}
            {remoteJobs}
          </div>
        </li>
      )}
      {jobType && (
        <li>
          <div className="job-details-text">
            {countryName === 'us' && (
              <img src={mediaApi.updateImageUrl(iconHours)} alt="type icon" />
            )}
            {`${t('job-type')}: `} {jobType}
          </div>
        </li>
      )}
    </ul>
  );
};

JobDetailsList.defaultProps = {
  items: [],
};

JobDetailsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default withSitecoreContext()(withTranslation()(JobDetailsList));

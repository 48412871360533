import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { showString, setAltUrlForImg } from '../../../utils/helperUtils';
import ReadMore from '../ReadMore';
import './cards.scss';

/**
 * @description - Card component for card scrolls.
 * @param {*} param0 - Input props.
 * @returns {Node} - HTML card.
 */
const CardLeader = ({ title, text, img }) => {
  const [textDesc, setTextDesc] = useState();

  useEffect(() => {
    setTextDesc(text?.value);
  }, [text]);
  return (
    <div className="card leadership card--leaderwipe">
      <div className="article-image" aria-hidden="true">
        <Image className="infoIcon" field={setAltUrlForImg(img, '')} />
      </div>
      <ReadMore cardVerticalPadding={24}>
        <h3 className="title">
          {title?.value ? <Text field={title} /> : showString(title)}
        </h3>
        <RichText field={{ value: textDesc }} tag="p" />
      </ReadMore>
    </div>
  );
};

CardLeader.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
};

CardLeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  img: PropTypes.shape({}),
  altText: PropTypes.string,
};

export default CardLeader;

import React, { useEffect, useState } from 'react';
import './saved-search.scss';

const SavedSearchSelect = ({ setSearchKeyword, alertList, t }) => {
  const [alerts, setAlert] = useState(null);
  const prepareTableRows = (alertList = []) => {
    const data = [];
    alertList.forEach((alerts, index) => {
      data[index] = {
        title: alerts?.jobAlertName,
        view: alerts?.frequencyType,
        searchKeyword: alerts?.searchKeyword,
        latitude: alerts?.latitude,
        longitude: alerts?.longitude,
        place: alerts?.place,
        filters: alerts?.filters,
        queryString: alerts?.queryString,
        id: alerts?.alertID,
      };
    });
    setAlert(data);
  };
  useEffect(() => {
    if (alertList) {
      prepareTableRows(alertList);
    }
  }, []);
  const extractFilterValues = (filters) => {
    const filterValues = [];

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const filter = filters[key];

        if (Array.isArray(filter)) {
          filter.forEach((item) => {
            if (item.value) {
              filterValues.push(item.value);
            }
          });
        }
      }
    }

    return filterValues;
  };
  return (
    <>
      <div className="dashboard-block options-popup ">
        <div className="table-title">{t('saved-search')}</div>
        {alerts &&
          alerts.map((jobAlerts, index) => (
            <div
              key={index}
              className="saved-search"
              role="button"
              tabIndex={0}
              onClick={() => {
                setSearchKeyword(jobAlerts);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchKeyword(jobAlerts);
                }
              }}
            >
              <div className="saved-header">
                <div className="search-icon" />
                <div className="saved-job-details">
                  <p>{jobAlerts.title}</p>
                </div>
              </div>
              <div className="filter-pill-container">
                {jobAlerts.filters &&
                  extractFilterValues(jobAlerts.filters).map((filterName, index) => (
                    <div className="filter-pill" key={index}>
                      {' '}
                      {filterName}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SavedSearchSelect;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // If you are using React Router
import './logout-success.scss';
import SiteLogo from '../../../assets/images/logo__mp.svg';
import ModalComponent from '../Modal';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authstates } from '../../../utils/enums';
const Logo = () => {
  return (
    <div className="text-center mb-4 logo-img-container">
      <img src={SiteLogo} alt="Logo" style={{ height: '80px', width: '180px' }} />
    </div>
  );
};

const LogoutSuccess = ({ t, login, jobSearchUrl }) => {
  const pushToStorage = () => {
    localStorage.setItem('authenticationType', 'Create an account/Sign in');
  };
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const handleModal = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    if (
      localStorage.getItem('logoutUser') === 'true' &&
      loginState === authstates.UNAUTHENTICATED
    ) {
      handleModal();
      localStorage.setItem('logoutUser', false);
    }
  }, []);
  return (
    <ModalComponent
      showModal={showModal}
      handleModal={handleModal}
      className="logoutModel"
    >
      <div key="body">
        <div className="modal-body ">
          <Logo />
          <h2> {t('logged-out-successfully-header')}</h2>
          <div className="loggedOutText">{t('successfully-logged-out-text')}</div>
        </div>
        <button
          className="primary-button orange-sd"
          onClick={() => {
            setShowModal(false);
            history.push(jobSearchUrl);
          }}
          onKeyDown={(e) => {
            if (e && (e.keyCode === 13 || e.keyCode === 32)) {
              setShowModal(false);
              history.push(jobSearchUrl);
            }
          }}
          tabIndex="0"
          aria-label="User Login"
          aria-expanded="false"
        >
          <div className="btn-job-search-icon"></div>
          {t('jobs-search-button')}
        </button>
        <button
          className="mx-2 primary-button "
          onClick={() => {
            pushToStorage();
            login();
          }}
          onKeyDown={(e) => {
            if (e && (e.keyCode === 13 || e.keyCode === 32)) {
              pushToStorage();
              login();
            }
          }}
          tabIndex="0"
          aria-label="User Login"
          aria-expanded="false"
        >
          <span>
            <div className="login__text"> {t('sign-in')}</div>
          </span>
        </button>
      </div>
    </ModalComponent>
  );
};
export default withSitecoreContext()(withTranslation()(LogoutSuccess));

/*eslint-disable*/
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
// import GooglePicker from 'react-google-picker';
import { UPLOAD_PLUGINS } from '../../../../../../constants';
import {
  dataURLtoFile,
  base64ArrayBuffer,
} from '../../../../../../utils/helperUtils';
import { connect } from 'react-redux';
import useDrivePicker from 'react-google-drive-picker';
import { useEffect, useState } from 'react';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const GoogleDrive = ({ inputRef, ...props }) => {
  const { onSuccess, config, setLoader } = props;
  const fileUpload = useRef();
  const [openPicker, authResponse] = useDrivePicker();
  const [dataDoc, setDataDoc] = useState();

  /**
   * @description To handle upload block select
   */

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      fileUpload.current.click();
      openPicker({
        clientId: config?.GDRIVE?.clientId,
        developerKey: config?.GDRIVE?.apiKey,
        viewId: 'DOCS',
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: false,
        callbackFunction: (data) => {
          if (data.action === google.picker.Action.PICKED) {
            setDataDoc(data);
          }
        },
      });
    },
  }));

  useEffect(() => {
    if (dataDoc) {
      handleSuccess(dataDoc?.docs[0], authResponse?.access_token);
    }
  }, [dataDoc]);

  /**
   * @description To handle file from Google drive
   * @param {*} file file chosen
   * @param {*} oauthToken access token
   * @returns {undefined} nothing
   */

  const handleSuccess = (file, oauthToken) => {
    setLoader();
    var id = file?.id;
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      'https://www.googleapis.com/drive/v3/files/' + id + '?alt=media',
      true
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + oauthToken);
    xhr.responseType = 'arraybuffer';
    const type = file?.mimeType;
    xhr.onload = function () {
      var base64 = `data:${type};base64,` + base64ArrayBuffer(xhr.response);
      const temp = dataURLtoFile(base64, file?.name);
      onSuccess(temp);
    };
    xhr.send();
  };

  return config &&
    config?.GDRIVE &&
    config?.GDRIVE?.apiKey &&
    config?.GDRIVE?.clientId &&
    config?.GDRIVE?.scope ? (
    <div ref={fileUpload}></div>
  ) : (
    <div />
  );
};
const mapStateToProps = (state) => {
  return {
    config: state.uploadConfigReducer.data,
  };
};
GoogleDrive.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
};

GoogleDrive.defaultProps = {
  onSuccess: () => {},
  img: '',
};

const ConnectedComponent = connect(mapStateToProps)(GoogleDrive);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});

/*eslint-disable*/
import { UPLOAD_PLUGINS } from '../../constants';
import { dataFetcher } from '../../dataFetcher';
import { saveConfig } from '../../redux/actions/actions';

/**
 * @description-  upload plugins config
 * @param {*} id
 * @param {*} cid
 */
export const uploadPluginConfigApi = (dispatch) => {
  dataFetcher(`${UPLOAD_PLUGINS.GETCONFIG}`, 'GET').then((res) => {
    dispatch(saveConfig(res.data));
  });
};

import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import ModalComponent from '../Modal';
import Countdown from './CountdownTimer';
import './SessionRedirection.scss';

const SessionRedirection = ({ loginUser, logoutUser }) => {
  const IdleTimeref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const countdownTimerInSeconds = 120; // this needs to change after client demo
  const minutes = Math.floor(countdownTimerInSeconds / 60);
  const seconds = countdownTimerInSeconds % 60;

  const convertSecToMin = (num) => {
    return num.toString().padStart(2, '0');
  };
  const countdownTimerValueInMin = `${convertSecToMin(minutes)}:${convertSecToMin(
    seconds
  )}`;
  const onIdle = () => {
    setShowModal(true);
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <IdleTimer
        ref={IdleTimeref}
        timeout={1000 * 60 * 5}
        onIdle={onIdle}
        crossTab={true}
        debounce={250}
      />

      <ModalComponent
        showModal={showModal}
        handleModal={handleModal}
        className="sessionModel"
        backdrop="static"
      >
        <div key="body" className="sessionpoup">
          <Countdown
            loginUser={loginUser}
            logoutUser={logoutUser}
            countdownTimerValue={countdownTimerValueInMin}
            counterValueInSeconds={countdownTimerInSeconds}
          />
        </div>
      </ModalComponent>
    </>
  );
};

export default SessionRedirection;

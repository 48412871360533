/* eslint-disable max-lines-per-function */
import React, { useEffect, useContext, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import TextboxComplex from '../../globals/forms/TextboxComplex';
import Button from '../../globals/buttons/Button';
import { HeaderContext } from '../../SiteHeader/HamburgerMenu/HeaderContext';

import LocationSearch from '../../globals/forms/LocationSearch';
import {
  isObjNotEmpty,
  getSearchURLParamsUpdated,
  parseFilterData,
  verifySearchJobPath,
  removeSpecialChars,
} from '../../../../utils/helperUtils';
import { ACCOUNT_SETTINGS, SEARCH } from '../../../../constants';
import { saveSelectedLocation } from '../../../../redux/actions';
import { removeFocus } from '../../../../redux/actions/actions';
import SavedSearchSelect from '../../globals/forms/SavedSearchSelect';
import { useFetchData } from '../../../../hooks/http-client';
/**
 * @description - Handle submit.
 */
const handleFormSubmit = ({
  values,
  urlVal,
  history,
  setMenuOpen,
  setMenuSection,
  searchLocation,
  params,
  textName,
  isInHeader,
  setValue,
  pathname,
  dispatch,
  sitecoreContext,
  amsjobs = false,
}) => {
  const URLVal =
    sitecoreContext?.Country?.jobSearchURL !== urlVal
      ? sitecoreContext?.Country?.jobSearchURL
      : urlVal;
  const paramVal = new URLSearchParams();
  if (isObjNotEmpty(searchLocation)) {
    dispatch(saveSelectedLocation(searchLocation));
  }
  dispatch(removeFocus(true));
  const finalParams = getSearchURLParamsUpdated(
    paramVal,
    values[textName],
    searchLocation
  );

  if (amsjobs) finalParams.append('showamsjobs', amsjobs);
  if (pathname === URLVal) {
    history.replace({
      pathname: URLVal,
      search: String(decodeURIComponent(finalParams)),
    });
  } else {
    history.push({
      pathname: URLVal,
      search: String(decodeURIComponent(finalParams)),
    });
  }
  setMenuOpen(false);
  setMenuSection('');
  if (isInHeader) {
    setValue(textName, null);
  } else {
    setValue(textName, params.get('searchKeyword'));
  }
};

/**
 *
 */
const FormElements = (props) => {
  const { isInHeader, textName, register, locationSelected, clearErrors } = props;
  const {
    sitecoreContext,
    searchLocation,
    onInputChange,
    handleSubmit,
    submitSavedAlert,
  } = props;
  const { getValues, locName, t, onSubmit } = props;
  const { errors, control } = props;
  const [isSavedSearchVisible, setIsSavedSearchVisible] = useState(false);
  const divRef = useRef(null);
  const regexValidationPattern = sitecoreContext?.Country?.regExPattern || '';
  const userSession = localStorage.getItem('usersession');
  const parsedUserData = JSON.parse(userSession);
  const authenticatedUser = parsedUserData?.state === 'AUTHENTICATED';
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const [alertList, setAlertList] = useState(null);
  const { load } = useFetchData();

  /**
   *
   * @param {*} searchLoc
   */
  const validateLocation = (searchLoc) => {
    return !(
      searchLoc &&
      (!isObjNotEmpty(searchLocation) ||
        (isObjNotEmpty(searchLocation) && searchLocation.place !== searchLoc))
    );
  };
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsSavedSearchVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (authenticatedUser && countryName === 'us') {
      getAlerts();
    }
  }, [authenticatedUser, countryName]);
  const getAlerts = () => {
    load(ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.GET_ALERT_API, null, setData);
  };
  const setData = (response) => {
    setAlertList(response.data.jobAlerts);
  };
  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)} ref={divRef}>
      <div className={`col-lg-6 ${isInHeader ? 'col-xl-5' : ''}`}>
        <TextboxComplex
          id={`sr_job_${isInHeader && 'header'}`}
          isSearchEnabled={false}
          setIsSavedSearchVisible={setIsSavedSearchVisible}
          name={textName}
          register={register({
            pattern: new RegExp(regexValidationPattern),
          })}
          errorMsg={errors && errors[textName] && t('search-validation-message')}
          value={getValues(textName)}
          showOrgVal
          label={
            isInHeader
              ? `${t('job-title-industry-skill')}`
              : `${t('jobs-search-keyword-label')}`
          }
        />
      </div>
      <div className={`col-lg-3 ${isInHeader ? 'search-pad-lg' : ''}`}>
        <Controller
          as={LocationSearch}
          control={control}
          onLocationSelect={locationSelected}
          label={
            isInHeader
              ? t('search-location-header')
              : t('jobs-search-location-label')
          }
          region={sitecoreContext?.Country?.code}
          errorMessage={t('invalid-location')}
          id={`sr_location_${isInHeader && 'header'}`}
          name={locName}
          disableEnterSubmit={true}
          refVal={register}
          rules={{
            validate: isSavedSearchVisible
              ? !isSavedSearchVisible
              : validateLocation,
          }}
          showError={errors && errors[locName]}
          selectedLocation={searchLocation}
          changeHandler={onInputChange}
          defaultValue={searchLocation?.place}
          showCurrentLocation={isInHeader ? true : false}
          focusOutHandler={() => {
            clearErrors(locName);
          }}
        />
      </div>
      <div className={`col-lg-3 ${isInHeader ? 'searchbtn-pad-lg col-xl-4' : ''}`}>
        <Button
          cssClass="reversed"
          text={t('search-jobsearch')}
          handleButtonClick={() => {
            setIsSavedSearchVisible(false);
            handleSubmit(onSubmit);
          }}
          typeProp={{ type: 'submit' }}
        />
      </div>
      {isSavedSearchVisible &&
        alertList &&
        authenticatedUser &&
        countryName === 'us' && (
          <div className="col-lg-12">
            <SavedSearchSelect
              t={t}
              alertList={alertList}
              setSearchKeyword={(alert) => {
                submitSavedAlert(alert);
                setIsSavedSearchVisible(false);
              }}
            />
          </div>
        )}
    </form>
  );
};

/**
 * @description - Job search form.
 * @param {object} props - Input props.
 * @returns {Node} -Search form.
 */
const JobSearchForm = (props) => {
  const { mode, t, location, sitecoreContext, urlVal } = props;
  const { isFilterExists, dispatch, locationState, mapInit } = props;
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const headerVals = useContext(HeaderContext) || {};
  const { isInHeader, setMenuOpen, setMenuSection, menuOpen } = headerVals;
  const textName = `searchJobText${isInHeader ? 'Header' : ''}`;
  const locName = `searchLocation${isInHeader ? 'Header' : ''}`;
  const expiredJobRegexPattern =
    sitecoreContext?.Country?.expiredJobRegExPattern || '';
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterVal = filter || {};
  let locValue = {};
  if (!isInHeader && (filterVal.latitude || filterVal.longitude) && mapInit) {
    locValue = {
      lat: filterVal.latitude,
      lng: filterVal.longitude,
      place: decodeURIComponent(filterVal.place || ''),
    };
  }
  const inputVal = removeSpecialChars(
    expiredJobRegexPattern,
    params.get('searchKeyword') || ''
  );
  const [searchLocation, setSrLocation] = useState(locValue || {});
  const [amsjobs, setAmsJobs] = useState(false);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    errors,
    control,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      searchJobText: params.get('mode') !== 'site' ? inputVal : '',
      searchJobTextHeader: '',
      searchLocation: '',
      searchLocationHeader: searchLocation?.place,
    },
  });

  /**
   * @description - On submit/search function.
   * @param {object} values - Form values.
   * @returns {undefined} - Sets the params and redirect.
   */
  const onSubmit = (values = {}) => {
    handleFormSubmit({
      values,
      urlVal,
      history,
      setMenuOpen,
      setMenuSection,
      searchLocation,
      params,
      textName,
      isInHeader,
      setValue,
      pathname: location?.pathname,
      dispatch,
      sitecoreContext,
      amsjobs,
    });
  };

  /**
   * @description function called followed by place selection
   * @param {object} locVal  - geometry of location
   * @returns {undefined} - no return
   */
  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue(locName, locVal.place);
      clearErrors(locName);
    } else {
      setSrLocation({});
    }
  };

  /**
   * @description - On input location change.
   * @param {string} value - Entered value.
   * @returns {undefined}
   */
  const onInputChange = (value) => {
    setValue(locName, value);
    if (value === '') {
      setSrLocation({});
      // This is needed since the location is placed at the url
      // and will prefill that location when search location textfield is cleared.
      params.delete('longitude');
      params.delete('latitude');
      params.delete('place');
      history.replace({
        pathname: urlVal,
        search: String(decodeURIComponent(params.toString())),
      });
      clearErrors(locName);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    setAmsJobs(params.get('showamsjobs'));
    if (
      !isInHeader &&
      params.get('mode') !== 'site' &&
      verifySearchJobPath(location?.pathname, urlVal, sitecoreContext?.language)
    ) {
      setValue(textName, inputVal);
      const filterObj = parseFilterData() || {};
      if (mapInit) {
        const locObj = {
          lat: filterObj.latitude,
          lng: filterObj.longitude,
          place: decodeURIComponent(filterObj.place || ''),
        };
        setSrLocation(locObj);
      }
      if (localStorage) {
        localStorage.setItem(
          SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY,
          JSON.stringify({
            searchKeyword: inputVal,
            filters: filterObj,
          })
        );
        localStorage.setItem(
          SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS,
          location?.search
        );
        localStorage.setItem('companyUrl', '');
      }
    }
  }, [location, isInHeader, setValue, menuOpen, mapInit]);

  useMemo(() => {
    if (isInHeader && isObjNotEmpty(locationState)) {
      setSrLocation(locationState);
    }
  }, [locationState]);

  useEffect(() => {
    if (isInHeader) {
      clearErrors(locName);
      setValue(textName, '');
    }
  }, [location]);
  const submitSavedAlert = (alert) => {
    const searchLocation = {
      lat: alert.latitude,
      lng: alert.longitude,
      place: alert.place,
    };
    const paramVal = new URLSearchParams(alert.queryString);
    const finalParams = getSearchURLParamsUpdated(
      paramVal,
      alert.searchKeyword,
      searchLocation
    );
    const URLVal =
      sitecoreContext?.Country?.jobSearchURL !== urlVal
        ? sitecoreContext?.Country?.jobSearchURL
        : urlVal;
    history.replace({
      pathname: URLVal,
      search: String(decodeURIComponent(finalParams)),
    });
  };
  return (
    <div
      id={`search-job-${isInHeader && 'header'}`}
      role="tabpanel"
      aria-labelledby="search-job-tab"
    >
      <div
        className={`search-jobs ${mode !== 'site' ? 'active' : ''} ${
          !isFilterExists && !isInHeader ? 'no-filter' : ''
        }`}
      >
        <FormElements
          textName={textName}
          setSrLocation={setSrLocation}
          register={register}
          locationSelected={locationSelected}
          sitecoreContext={sitecoreContext}
          searchLocation={searchLocation}
          onInputChange={onInputChange}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isInHeader={isInHeader}
          t={t}
          getValues={getValues}
          setValue={setValue}
          locName={locName}
          errors={errors}
          control={control}
          reset={reset}
          clearErrors={clearErrors}
          submitSavedAlert={submitSavedAlert}
        />
      </div>
    </div>
  );
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    locationState: state.jobLocationReducer.location,
    mapInit: state.mapReducer?.data,
  };
};

export default connect(mapStateToProps)(withSitecoreContext()(JobSearchForm));

JobSearchForm.propTypes = {
  mode: PropTypes.string.isRequired,
  isInHeader: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

JobSearchForm.defaultProps = {
  mode: '',
  isInHeader: false,
  t: () => {},
};

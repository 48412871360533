import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from '../../core/globals/buttons/Button';
import Select from 'react-select';
import './skillsmodal.scss';
import '../../core/Forms/forms.scss';
import { useSelector } from 'react-redux';
import { fillInAddress } from '../Utils/helper';
import { isObjNotEmpty } from '../../../utils/helperUtils';
import { CREATE_PROFILE } from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import { authstates, countryCodesList } from '../../../utils/enums';
import ModalComponent from '../../core/Modal';

const SkillsAndZipcodeModal = ({ t, sitecoreContext }) => {
  const [showModal, setShowModal] = useState(true);
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [locationAdress, setLocationAddress] = useState({});
  const autoCompleteZipRef = useRef(null);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [formVal, setFormVal] = useState({
    zipCode: '',
    skills: [],
  });
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const authenticatedUser = loginState === authstates.AUTHENTICATED;
  const isUserInUS =
    authenticatedUser && profileInfo && profileInfo?.Id && countryName === 'us';
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur', // Validate on blur
  });

  const formattSkillsData = (response) => {
    const formattedData =
      response?.map((item) => {
        if (item) {
          const tempObj = {
            value: item.id,
            label: item.id,
          };
          return tempObj;
        }
      }) || [];
    return formattedData;
  };
  useEffect(() => {
    if (isObjNotEmpty(locationAdress)) {
      const value = `${locationAdress.zip} ${locationAdress.city}, ${locationAdress.country}`;
      setValue('zipCode', value);
    }
  }, [locationAdress]);

  useEffect(() => {
    if (isUserInUS) {
      dataFetcher(CREATE_PROFILE.GET_CREATE_PROFILE.GET_SKILLS_MASTER_DATA).then(
        (response) => {
          const data = formattSkillsData(response?.data);
          setSkillsOptions(data);
        }
      );
      const address = profileInfo?.PersonalDetails?.data?.[0]?.address || {};
      setValue('zipCode', address?.zip);
    }
  }, [isUserInUS]);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const onSubmit = () => {
    setShowModal(!showModal);
  };

  const colourStyles = {
    control: (styles, provided, state, isFocused) => ({
      ...styles,
      cursor: 'pointer',
      border: isFocused ? '2px solid black' : '1px solid gray',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black', // Border color on hover
      },
    }),
  };

  const initPlaceSearch = () => {
    if (scriptLoaded === false) {
      if (window?.google) {
        handleScriptLoad();
        setScriptLoaded(true);
      }
    }
  };

  const handleScriptLoad = () => {
    if (window.google.maps.places?.Autocomplete) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteZipRef.current,
        {
          types: ['postal_code'],
          componentRestrictions: {
            country: [countryName],
          },
          fields: ['address_components', 'formatted_address', 'geometry'],
        }
      );
      if (countryCodesList) {
        autoComplete.setComponentRestrictions({
          country: countryCodesList.split(','),
        });
      }
      autoComplete.addListener('place_changed', () => {
        handlePlaceSelect(autoComplete);
      });

      enableEnterKey(autoCompleteZipRef.current);
    }
  };
  function enableEnterKey(input) {
    /* Store original event listener */
    const _addEventListener = input.addEventListener;

    const addEventListenerWrapper = (type, listener) => {
      if (type === 'keydown') {
        /* Store existing listener function */
        const _listener = listener;
        listener = (event) => {
          /* Simulate a 'down arrow' keypress if no address has been selected */
          const suggestionSelected = document.getElementsByClassName(
            'pac-item-selected'
          ).length;
          if (event.key == 'Shift') return false;
          if (
            (event.key === 'Tab' || event.key === 'Enter') &&
            !suggestionSelected
          ) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });
            _listener.apply(input, [e]);
          }
          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };

    input.addEventListener = addEventListenerWrapper;
  }
  async function handlePlaceSelect(autoComplete) {
    const addressObject = await autoComplete.getPlace();
    const address = addressObject?.address_components;
    if (address) {
      const formatAddress = fillInAddress(address);
      setLocationAddress(formatAddress);
    } else {
      setLocationAddress({});
    }
  }

  return (
    <>
      {isUserInUS ? (
        <ModalComponent
          showModal={showModal}
          handleModal={handleModal}
          size="lg"
          backdrop="static"
        >
          <div className="inner-modal-container skillsModal" key="body">
            <div className=" text-center">
              <h2 className="title">{t('additional-information')}</h2>
              <p> {t('additional-details')}</p>
            </div>

            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row forms">
                    <div className="col-lg-12 form-block text-left">
                      <h4> {t('zipCode')}</h4>
                      <p> {t('zipcode-details')}</p>
                      <div className="zip-code form-block">
                        <Controller
                          name="zipCode"
                          control={control}
                          defaultValue=""
                          render={({ onChange, value, field }) => (
                            <input
                              {...field}
                              ref={autoCompleteZipRef}
                              type="text"
                              value={value || ''}
                              onChange={(selected) => {
                                const selectedVal = selected?.target?.value
                                  ? selected?.target?.value
                                  : selected;
                                onChange(selectedVal);
                                setLocationAddress({});
                                initPlaceSearch();
                              }}
                              placeholder={t('additional-information')}
                            />
                          )}
                          rules={{ required: t('zipCode-required') }} // Add required validation rule
                        />
                        {errors.zipCode && (
                          <span className="error-msg">{errors.zipCode.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 form-block text-left">
                      <h4> {t('expertise-skills')}</h4>
                      <p> {t('expertise-skills-details')}</p>
                      <div className="skills-select">
                        <div className={`form-block skills`}>
                          <Controller
                            name="skills"
                            control={control}
                            placeholder={t('skills')}
                            defaultValue={[]}
                            render={({ field, value }) => (
                              <Select
                                {...field}
                                isMulti
                                closeMenuOnSelect={false}
                                placeholder={t('skills')}
                                options={skillsOptions}
                                menuPlacement="auto"
                                value={value}
                                styles={colourStyles}
                                onChange={(selectedOptions) => {
                                  setSelectedSkills(selectedOptions);
                                  setFormVal({
                                    ...formVal,
                                    skills: selectedOptions,
                                  });

                                  setValue('skills', selectedOptions);
                                }}
                              />
                            )}
                            rules={{ required: t('skills-required') }}
                          />
                        </div>
                      </div>
                      {errors.skills && (
                        <div className="error-msg">{errors.skills.message}</div>
                      )}
                    </div>
                    <div className="col-lg-12 selected-skilss-container">
                      {selectedSkills &&
                        selectedSkills.map((skill, index) => (
                          <div className="rounded-pill skill-pill" key={index}>
                            <span key={skill.value}>{skill.label}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="save-btn text-center">
                    <Button text={t('save-changes')} type="submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ModalComponent>
      ) : null}
    </>
  );
};

export default withSitecoreContext()(withTranslation()(SkillsAndZipcodeModal));

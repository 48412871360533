/*eslint-disable*/
import { showconsole, STORAGE } from '../constants';
import { toJson } from 'really-relaxed-json';
import { checkValueTypeAndGetTheCount } from '../components/business/Utils/helper';

export const checkArray = (data) => data || [];

export const isNullorEmpty = (data) =>
  data === null || data === '' || data === undefined;

export const checkRouteField = (route) =>
  route && route.fields && checkArray(route.fields.items);

export const isDataExists = (data) => data && data.length;

export const truncate = (input, length) =>
  input && input.length > length ? `${input.substring(0, length)}...` : input;

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const checkUrl = (route, url = 'url') =>
  route &&
  route.fields &&
  route.fields[url] &&
  route.fields[url].value &&
  route.fields[url].value.href;

export const setAltUrlForImg = (img, alt) => {
  if (img && img.value) {
    if (img.value.src && !img.value.alt) {
      img.value.alt = alt;
    }
  } else if (img && img.src) {
    if (!img.alt) {
      img.alt = alt;
    }
  }
  return img;
};

export const getImg = (img) => img && img.value && img.value.src;

export const checkDataExists = (data, field = 'value') => data && data[field];

export const checkColor = (color) =>
  color && typeof color === 'string' && color.toUpperCase();

export const checkHref = (url) => url?.value?.href || url?.href;

export const checkQueryParam = (url) =>
  url?.value?.querystring || url?.querystring ? true : false;

export const checkQueryString = (url) => url?.value?.querystring || url?.querystring;

export const checkLinkType = (link) =>
  link?.value?.linktype === 'internal' || link?.linktype === 'internal';

export const checkString = (data, field = 'value') =>
  data && data[field] && typeof data[field] === 'string' ? data[field] : '';

export const isObjNotEmpty = (obj) => obj && Object.keys(obj).length;

export const showString = (data) => (data && typeof data === 'string' ? data : '');
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getLastIndexOfString = (val) => val?.split('.')?.pop() || val;
export const checkJSSObject = (obj) => {
  if (isObjNotEmpty(obj)) {
    if (obj.value) {
      const val = obj.value;
      return val.hasOwnProperty('href');
    }
    return obj.hasOwnProperty('href');
  }
  return false;
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * @description to get video id from youtube url
 * @param {string} url - video url
 * @returns {string} - video id/false
 */
export const youtubeParser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};
export const vimeoParser = (url) => {
  var re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
  var matches = re.exec(url);
  return matches && matches[1];
};

/**
 * @description to convert base64 to File
 * @param {string} dataurl - base64
 * @param {string} filename - filename
 * @returns {Object} -File Object
 */
export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  //IE Fix
  try {
    return new File([u8arr], filename, { type: mime });
  } catch (err) {
    // new File not supported in IE and partially supported in edge
    return new Blob([u8arr], filename, { type: mime });
  }
};

export const getFileFromUrl = (url, name, onSuccess) => {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    let reader = new FileReader();
    reader.onload = function (event) {
      let res = event.target.result;
      const temp = dataURLtoFile(res, name);
      onSuccess(temp);
    };
    let file = this.response;
    const cont = reader.readAsDataURL(file);
  };
  xhr.send();
};

export const base64ArrayBuffer = (arrayBuffer) => {
  var base64 = '';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18;
    b = (chunk & 258048) >> 12;
    c = (chunk & 4032) >> 6;
    d = chunk & 63;

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2;

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4;

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10;
    b = (chunk & 1008) >> 4;

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2;

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  return base64;
};

/**
 * @description - Get url search params for job.
 * @param {URLSearchParams} params - URL params.
 * @param {string} searchKeyword - Search Keyword.
 * @param {object} location - Selected location.
 * @returns {URLSearchParams} - URL params to filter.
 */
export const getSearchURLParams = (params, searchKeyword, location) => {
  params.set('page', 1);
  if (searchKeyword) {
    params.set('searchKeyword', searchKeyword);
  }
  if (isObjNotEmpty(location) && (location.lat || location.lng || location.place)) {
    const filterObj = {
      latitude: location.lat,
      longitude: location.lng,
      place: location.place,
    };
    const filterStr = JSON.stringify(filterObj);
    params.set('filters', encodeURIComponent(filterStr));
  }
  return params;
};

/**
 * @description - Sets the ids param as last value.
 * @param {Object} params - Params object.
 * @returns {undefined} - returns undefined.
 */
export const setIdsParamAtLast = (params) => {
  const idParams = params.get('ids');
  const selectedFilter = params.get('sf');
  if (idParams) {
    params.delete('ids');
    params.append('ids', idParams);
  }
  if (selectedFilter) {
    params.delete('sf');
    params.append('sf', selectedFilter);
  }
  return;
};

export const getSearchURLParamsUpdated = (params, searchKeyword, location) => {
  params.set('page', 1);
  if (searchKeyword) {
    params.set('searchKeyword', searchKeyword);
  }
  if (location && location.lat && location.lng && location.place) {
    params.set('latitude', location.lat);
    params.set('longitude', location.lng);
    params.set('place', location.place);
  }
  //Sets the ids parameter as last
  setIdsParamAtLast(params);
  return params;
};

/**
 * @description - Clear all filter params.
 * @param {URLSearchParams} params - URL search params.
 * @returns {URLSearchParams} - Refined params.
 */
export const clearParams = (params) => {
  params.delete('searchKeyword');
  params.delete('filters');
  params.delete('mode');
  params.delete('searchText');
  params.delete('jobDetails');
  return params;
};

/**
 * @description - Filter input array by Type.
 * @param {string} params - Filter type.
 * @returns {Array} - Refined array.
 */
export const filterArrayByType = (array = [], type) => {
  const newArray = array?.filter(function (item) {
    return item?.type === type;
  });
  return newArray;
};

/**
 * @description - Filter input array by Type.
 * @param {string} params - Filter type.
 * @returns {Array} - Refined array.
 */
export const filterArrayByStatus = (array, type, status) => {
  const fileList = filterArrayByType(res.data, type);
  const newArray = fileList.filter(function (item) {
    return item.status === status;
  });
  return newArray;
};

/**
 * @description - Replace the placeholder of a text with JSX tag.
 * @param {string} text - Text.
 * @param {string} placeholder - Placeholder to be replaced.
 * @param {Node} jsxTag - JSX Tag to be replaced in place of Placeholder.
 * @returns {Array} - Refined array.
 */
export const replaceTextwithJSX = (text, placeholder, jsxTag) => {
  let array = text?.split(placeholder);
  const mappingFunction = (part) => {
    return [part, jsxTag];
  };
  var result = [];
  for (var i = 0; i < array.length; i++) {
    var mapping = mappingFunction(array[i]);
    result = result.concat(mapping);
  }
  // Remove the last spacer
  result.pop();
  return result;
};

export const dataURItoBlob = (dataURI) => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  return blob;
};

export const getPageTitle = (route) => {
  let title = 'Welcome to ManpowerGroup';
  if (route?.placeholders && route?.placeholders['jss-header']) {
    const metaDataComp = route?.placeholders['jss-header'].find(
      (comp) => comp.componentName === 'MetaData'
    );
    if (metaDataComp) {
      title = metaDataComp.fields?.items?.metaTitle;
    }
  }
  return title;
};

/**
 * @description - Checks if device is iOS.
 * @returns {boolean} - Returns true or false.
 */
export const isiOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const pushNavToDataLayer = (navLocation, label) => {
  if (typeof window !== undefined && window?.dataLayer) {
    window.dataLayer.push({
      event: 'Navigation',
      eventCategory: 'Navigation Click',
      user_logged_in: localStorage.getItem(STORAGE.UUID) ? 'yes' : 'no',
      user_logged_in_id: localStorage.getItem(STORAGE.UUID) || null,
      eventAction: navLocation,
      eventLabel: label,
    });
  }
};

export const pushSearchPageToDataLayer = (prevLocation, location, title) => {
  if (prevLocation?.search && prevLocation?.search !== location?.search) {
    window.dataLayer.push({
      event: 'PageLoad',
      page: `${location?.pathname}${location?.search}`,
      pageTitle: title,
      user_logged_in: localStorage.getItem(STORAGE.UUID) ? 'yes' : 'no',
      user_logged_in_id: localStorage.getItem(STORAGE.UUID) || null,
    });
  }
};

export const cookiePolicy = () => {
  if (document.getElementById('ot-sdk-btn'))
    document.getElementById('ot-sdk-btn').click();
};

export const JSONParser = (raw, returnValue = {}) => {
  let item = typeof raw !== 'string' ? JSON.stringify(raw) : raw;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return returnValue;
  }

  if (typeof item === 'object' && item !== null) {
    return item;
  }

  return returnValue;
};

export const traverseAndDataExist = (data = {}, noValidationKey = []) => {
  let res = false;
  for (let item in data) {
    if (data?.[item] && data?.[item] !== null && !noValidationKey?.includes(item)) {
      if (typeof data?.[item] === 'string' || typeof data?.[item] === 'boolean') {
        res = checkValueTypeAndGetTheCount(data?.[item]);
        if (res) break;
      } else if (Array.isArray(data?.[item])) {
        data?.[item]?.map((d) => {
          res = traverseAndDataExist(d);
        });
        if (res) break;
      } else if (data?.[item] && Object.keys(data?.[item]).length > 0) {
        res = traverseAndDataExist(data?.[item]);
      }
    }
  }
  return res;
};

export const parseFilterData = () => {
  const params = new URLSearchParams(location?.search);
  let filterObj = {};

  const filterIds = JSON.parse(toJson(decodeURIComponent(params.get('ids')))) || {};

  const urlString = location.search && location.search.replace('?', '');
  let items = urlString && urlString.split('&');
  if (Array.isArray(items)) {
    items.forEach((params) => {
      let parsedParams = params.split('=');
      if (
        parsedParams.length > 0 &&
        parsedParams[1] !== undefined &&
        parsedParams[1].split(',').length > 1 &&
        parsedParams[0] !== 'ids' &&
        parsedParams[0] !== 'place' &&
        parsedParams[0] !== 'longitude' &&
        parsedParams[0] !== 'latitude' &&
        parsedParams[0] !== 'language' &&
        parsedParams[0] !== 'salaryMin' &&
        parsedParams[0] !== 'salaryMax' &&
        parsedParams[0] !== 'searchKeyword'
      ) {
        filterObj[parsedParams[0]] = [];
        parsedParams[1].split(',').forEach((value, index) => {
          filterObj[parsedParams[0]].push({
            value: decodeURIComponent(value.split('+').join(' ')),
            key: filterIds[parsedParams[0]] ? filterIds[parsedParams[0]][index] : '',
          });
        });
      } else
        switch (parsedParams[0]) {
          case 'ids':
            break;
          case 'place':
            filterObj[parsedParams[0]] = parsedParams[1].split('+').join(' ');
            break;
          case 'salaryMin':
          case 'salaryMax': {
            !filterObj?.salaryRange ? (filterObj.salaryRange = {}) : true;
            parsedParams[0] === 'salaryMin'
              ? (filterObj.salaryRange.min = parsedParams[1])
              : (filterObj.salaryRange.max = parsedParams[1]);
            break;
          }
          case 'selectedDistance': {
            var newKey = filterIds[parsedParams[0]]
              ? filterIds[parsedParams[0]].join(' ')
              : filterIds[parsedParams[0]][0];

            {
              filterIds[parsedParams[0]]
                ? (filterObj[parsedParams[0]] = [
                    {
                      value: decodeURIComponent(
                        parsedParams[1].split('+').join(' ')
                      ),

                      key: newKey,
                    },
                  ])
                : (filterObj[parsedParams[0]] = parsedParams[1]);
            }

            break;
          }
          default: {
            filterIds[parsedParams[0]]
              ? (filterObj[parsedParams[0]] = [
                  {
                    value: decodeURIComponent(parsedParams[1].split('+').join(' ')),
                    key: filterIds[parsedParams[0]][0],
                  },
                ])
              : (filterObj[parsedParams[0]] = parsedParams[1]);
          }
        }
    });

    return filterObj;
  }
};

export const keyEnter = (e, callback = () => {}) => {
  if (e.keyCode === 32 || e.keyCode === 13) {
    callback();
  }
};

export const stickyHeader = (siteHeader) => {
  try {
    let mobileSlider = document.querySelector('.mobile--actives');
    let styles = getComputedStyle(mobileSlider);
    siteHeader.classList.add('sticky');
    siteHeader.classList.remove('transparent');
    if (styles.display == 'none') {
      document.getElementsByTagName('body')[0].style.cssText = 'padding-top:65px';
    } else {
      document.getElementsByTagName('body')[0].style.cssText = 'padding-top:96px';
    }
  } catch (err) {}
};

export const dataTransform = (data, buildProfileItems) => {
  let tempData = {};
  Object.keys(data).forEach((k, index) => {
    if (buildProfileItems[k]) {
      tempData[buildProfileItems[k]] = data[k];
    }
  });
  return tempData;
};

/**
 * @description - Function to show console logs.
 * @param {*} key - Key.
 * @param {*} data - Data to show.
 */
export const consoleLog = (key, ...data) => {
  if (showconsole) {
    if (data?.length > 0) console.log(key, data);
    else console.log(key);
  }
};

export const getWidth = () =>
  typeof window !== undefined
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : 0;

/**
 * @description Remove tab index from slider.
 * @param {string|number} uid - Unique rendering id for component.
 * @returns {undefined}
 */
export const removeSlideTab = (uid) => {
  try {
    if (typeof window !== 'undefined') {
      document.querySelectorAll(`.scroll_${uid} .slick-slide`).forEach((node) => {
        node.removeAttribute('tabindex');
      });
    }
  } catch (err) {}
};

/**
 * @description - Validate whether current page is job search
 * @param {string} path - Location pathname.
 * @param {string} jobUrl - Job search url from sitecore
 * @param {string} language - language code from sitecore
 * @returns {boolean} Verified result.
 */
export const verifySearchJobPath = (path, jobUrl, language) => {
  const pathWOlang = path?.replace(`/${language}/`, '/');
  const jobUrlWOlang = jobUrl?.replace(`/${language}/`, '/');
  return pathWOlang === jobUrlWOlang;
};

/**
 * @description - Function to get title.
 * @param {*} title - Title.
 * @returns {string} Title
 */
export const getTitle = (title) => {
  switch (title) {
    case 'radius':
    case 'selectedDistance':
      return 'jobs-search-distance';
    case 'salaryRange':
      return 'salary-label';
    case 'salaryRangeLevels':
      return 'salary-label';
    case 'cities':
      return 'jobs-neighborhood';
    case 'industries':
      return 'jobs-all-industries';
    case 'jobOfferType':
      return 'job-offer-type';
    case 'workingHours':
      return 'working-hours';
    case 'sortOrder':
      return 'sort-order';
    case 'contractType':
      return 'contract-type';
    case 'employmentType':
      return 'jobs-hours';
    case 'jobTypes':
      return 'jobs-job-type';
    case 'scheduleType':
      return 'jobs-schedule-type';
    case 'showDisabilityIcon':
      return 'disable-at-work';
    case 'education':
      return 'education';
    case 'experienceLevel':
      return 'experience-level';
    case 'managementLevel':
      return 'jobs-management-level';
    case 'language':
      return 'filter-language';
    case 'specialization':
      return 'specialization';
    case 'remoteJobs':
      return 'remote-jobs-filter';
    case 'sectors':
      return 'sectors-filter';
    case 'publicationDates':
      return 'publication-dates-filter';
    default:
      return '';
  }
};

export const removeSpecialChars = (regex, data) => {
  const ctoregx = new RegExp(regex).source;
  const regexFormat = RegExp(ctoregx, 'g');
  return data?.replace(regexFormat, '');
};

export const generateID = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * @description - Check if the value(string) is "Yes" then return true or false.
 * @param {string} value - string value "Yes" or "No".
 * @returns {boolean} - return boolean.
 */
export const stringToBoolean = (value) => {
  if (value === 'Yes') {
    return true;
  } else {
    return false;
  }
};

/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PublicPosition from './PublicPosition';
import ScreenerQuestions from './ScreenerQuestions';
import JobNav from './JobNav';
import { scrollTop, isObjNotEmpty } from '../../../utils/helperUtils';
import { GET_JOB_DETAILS_BY_ID } from '../../../constants';
import { authstates } from '../../../utils/enums';
import { dataFetcher } from '../../../dataFetcher';
import Loader from '../../core/ErrorBoundary/Loader';
import Error from '../../core/ErrorBoundary/Error';
import Button from '../../core/globals/buttons/Button';
import { clearJobDetails } from '../../../redux/actions/actions';
import './apply_job_flow.scss';
import JobApply from './JobApply';
import {
  getCandidateDetailsApi,
  storeJobDetails,
} from '../../../services/apiServices/candidateService';
import { uploadPluginConfigApi } from '../../../services/apiServices/commonService';
// import { mockJobDetailsData } from './jobDetails';
// import { mockData } from './mockData';

/**
 * @description - Job Apply component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const ApplyJobFlow = ({ rendering, t, sitecoreContext, ...props }) => {
  //rendering = mockData;
  const JobContentArr = rendering?.placeholders?.['jss-content'] || [];
  const { params } = rendering;
  const { isSpontaneous } = params;
  const { fields = {} } = props;
  const { errorPageUrl = {} } = fields;
  const [step, setStep] = useState({});
  const [jobStorageDetails, setJobStorageDetails] = useState({});
  const [isScreenerLoading, setScreenerLoading] = useState(false);
  const profileInfo = useSelector((state) => state.profileInfoReducer);
  const loginReducer = useSelector((state) => state.loginReducer);
  const uploadConfigReducer = useSelector((state) => state.uploadConfigReducer);
  const [profileDataLoading, setprofileDataLoading] = useState(false);
  const [profileError, setProfileError] = useState(false);
  const [jobDataLoading, setJobLoading] = useState(true);
  const [error, setError] = useState(false);
  const step0Ref = useRef(null);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const queryStrings = new URLSearchParams(useLocation().search);

  /**
   * @description - Get Job Details By Job Id fuction.
   * @returns {undefined}
   */
  const getJobDetails = () => {
    dataFetcher(`${GET_JOB_DETAILS_BY_ID}?jobItemID=${queryStrings.get('id')}`).then(
      (response) => {
        if (response?.data?.isApplied) {
          storeJobDetails(response?.data, loginReducer, {}, '', t);
          errorPageUrl?.value?.href
            ? history.push({
                pathname: errorPageUrl?.value?.href,
                search: response?.data?.applicationStatus
                  ? `?id=${response?.data?.applicationStatus}`
                  : errorPageUrl?.value?.querystring || '?id=AlreadyApplied',
              })
            : isSpontaneous
            ? history.push(
                `/${
                  sitecoreContext?.language || ''
                }/candidate/spontaneous-jobapply-failure?id=${
                  response?.data?.applicationStatus || 'AlreadyApplied'
                }`
              )
            : history.push(
                `/${sitecoreContext?.language || ''}/candidate/jobapply-failure?id=${
                  response?.data?.applicationStatus || 'AlreadyApplied'
                }`
              );
        } else {
          if (response?.data?.jobItemID) {
            setContent(response?.data);
            // setContent(mockJobDetailsData);
          } else {
            setError(true);
          }
          setJobLoading(false);
        }
      },
      () => {
        setJobLoading(false);
        setError(true);
      }
    );
  };

  useEffect(() => {
    if (!queryStrings.get('id')) {
      history.push({
        pathname: sitecoreContext?.Country?.jobSearchURL || '/',
      });
    } else {
      //File Upload Configuration
      if (!isObjNotEmpty(uploadConfigReducer?.data)) uploadPluginConfigApi(dispatch);
      getJobDetails();
      //getMockData();
    }
    return () => {
      dispatch(clearJobDetails());
    };
  }, []);

  useEffect(() => {
    if (loginReducer?.state === authstates.AUTHENTICATED) {
      setprofileDataLoading(profileInfo?.loading);
      setProfileError(profileInfo?.data?.error);
    } else {
      setprofileDataLoading(false);
      setProfileError(false);
    }
  }, [profileInfo, loginReducer]);

  const getMockData = () => {
    setContent(mockJobDetailsData);
    setJobLoading(false);
  };

  const handleProfileApiRefresh = () => {
    setprofileDataLoading(true);
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };

  const setContent = (data) => {
    setJobStorageDetails(data);
    setStep({
      activeStep: data?.isPublicPosition ? 0 : data?.hasScreenerQuestion ? 1 : 2,
    });
  };

  /**
   * @description - HandleOnClickNext fuction.
   * @returns {undefined}
   */
  const handleOnClickNext = () => {
    switch (step.activeStep) {
      case 0:
        step0Ref.current.trigger();
        break;
      case 1:
        step1Ref.current.trigger();
        break;
      case 2:
        step2Ref.current.trigger();
        break;
      default:
        break;
    }
  };

  /**
   * @description - onSubmitHandler fuction.
   * @returns{undefined}
   */
  const onSubmitHandler = () => {
    let activeStep = step.activeStep;
    if (activeStep === 0 && jobStorageDetails?.hasScreenerQuestion === false) {
      activeStep = 1;
    }
    activeStep += 1;
    setStep({ activeStep });
    scrollTop();
  };

  /**
   * @description - HandleOnClickBack fuction.
   * @returns {undefined}
   */
  const handleOnClickBack = () => {
    const activeStep = step.activeStep - 1;
    setStep({ activeStep: activeStep });
    scrollTop();
  };

  const handleRedirectButtonClick = () => {
    history.push({
      pathname: sitecoreContext?.Country?.jobSearchURL || '/',
    });
  };

  const getBlockComponent = (block) => {
    switch (block.componentName) {
      case 'PublicPosition':
        return (
          <PublicPosition
            key={block?.uid}
            fields={block?.fields}
            onSubmitHandler={onSubmitHandler}
            ref={step0Ref}
            candidateJobDetails={jobStorageDetails}
            t={t}
          />
        );

      case 'ScreenerQuestions':
        return (
          <ScreenerQuestions
            key={block?.uid}
            fields={block?.fields}
            onSubmitHandler={(loading, status) => onSubmitHandler(loading, status)}
            ref={step1Ref}
            candidateJobDetails={jobStorageDetails}
            t={t}
          />
        );
      case 'UploadResume':
        return (
          <JobApply
            key={block?.uid}
            fields={block?.fields}
            candidateJobDetails={jobStorageDetails}
            ref={step2Ref}
            t={t}
            context={sitecoreContext}
          />
        );
      default:
        return <div className="no_block_type" />;
    }
  };

  return (
    <>
      {jobDataLoading || profileDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : error || profileError ? (
        <div className="text-center col-lg-6 offset-lg-3 job-apply-error-container">
          <Error
            errorMsg={error ? t('no-job-found') : t('error-message')}
            refreshApi={true}
            handleApiRefresh={() => {
              if (error) {
                setJobLoading(true);
                getJobDetails();
              } else {
                handleProfileApiRefresh();
              }
            }}
          />
          <Button
            text={t('go-to-search')}
            handleButtonClick={handleRedirectButtonClick}
            cssClass="profile-nav-next"
          />
        </div>
      ) : (
        <div className="candidate-flow">
          <div className="col-lg-6 offset-lg-3 job-title">
            {!isSpontaneous ? (
              <>
                <span>{t('you-are-applying-to')}&nbsp;</span>
                <span className="job-name">
                  <Link to={jobStorageDetails?.jobURL}>
                    {jobStorageDetails?.jobTitle}
                  </Link>
                </span>
              </>
            ) : (
              <span>{t('Spontaneous-Job-Title')}&nbsp;</span>
            )}
          </div>
          {JobContentArr.map((block, index) => {
            return step.activeStep === index && getBlockComponent(block, index);
          })}
          {step.activeStep !== JobContentArr.length - 1 && (
            <JobNav
              activeStep={step.activeStep}
              handleOnClickNext={handleOnClickNext}
              handleOnClickBack={handleOnClickBack}
              jobStorageDetails={jobStorageDetails}
              t={t}
              loading={isScreenerLoading}
              disabled={isScreenerLoading || jobDataLoading}
            />
          )}
        </div>
      )}
    </>
  );
};

ApplyJobFlow.defaultProps = {
  JobContent: [],
  t: (val) => val,
  sitecoreContext: {},
};

ApplyJobFlow.propTypes = {
  JobContent: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(
  withTranslation()(withPlaceholder('jss-content')(ApplyJobFlow))
);
